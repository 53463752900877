import styled, { css } from 'styled-components';
import { theme } from '@theme';

const textSizing = css`
    font-size: 2.2rem;
    font-weight: 800;

    ${theme.mediaQuerys.smUp} {
        font-size: 3rem;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 4rem;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 4.5rem;
    }
`

export const StyledSearchResultsHeroContainer = styled.section`
    background-color: black;
    color: ${theme.colors.white};
    
    .content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 135px;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;

        ${theme.mediaQuerys.smUp} {
            min-height: 150px;
        }

        ${theme.mediaQuerys.mdUp} {
            min-height: 165px;
            flex-direction: row;
        }
    }
`

export const StyledTitle = styled.h1`
    width: 100%;
    margin-bottom: .4rem;
    text-align: center;    
    ${textSizing}
`

export const StyledActiveOptionAndDropdownContainer = styled.div`
    display: inline;
    position: relative;
`

export const StyledSearchInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 350px;
    max-width: 100%;
    position: relative;
    padding-bottom: 6px;
    ${textSizing}

    ${theme.mediaQuerys.smUp} {
        width: 450px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-left: .75rem;
        padding-top: 3px;
        width: 550px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-left: 1.4rem;
        padding-bottom: 10px;
        padding-top: 5px;
        width: 650px;
    }

    &::before { // underline design element
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid white;  
    }

    .search-icon {
        width: 25px;

        ${theme.mediaQuerys.smUp} {
            width: 30px;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 35px;
        }

        ${theme.mediaQuerys.lgUp} {
            width: 40px;
        }
    }
`

export const StyledSearchInput = styled.input`
    text-align: center;
    background-color: transparent;
    outline: 0;
    border: none;
    width: 100%;
    color: ${theme.colors.white};
    ${textSizing}
    padding-left: 25px;

    ${theme.mediaQuerys.smUp} {
        padding-left: 30px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-left: 35px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-left: 40px;
    }
`