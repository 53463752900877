import React, { useEffect, useRef, useState } from 'react'
import { StyledSearchIcon, StyledSearchInputWrapper, StyledActiveOptionAndDropdownContainer, StyledSearchResultsHeroContainer, StyledOptionItem, StyledTitle, StyledSearchInput, StyledOptionsList} from './styles';

import Arrow from '@commons/arrow'
import SearchIcon from '@images/search-icon.svg';

export default function SearchResultsHero({ 
    title, 
    searchText,
    handleChange,
    handleKeyDown,
}) {    
    return (
        <StyledSearchResultsHeroContainer
            data-name="search-results-hero"
        >
            <div className="content-container container">
                <StyledTitle>
                    {title}
                </StyledTitle> 
                    
                <StyledSearchInputWrapper>
                    <StyledSearchInput
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        // disabled={isSubmitting ? 'disabled' : ''}
                        value={searchText}
                        placeholder={'Everything'}
                        className="active-option title-font"
                        type="search" 
                        name="" 
                        id=""
                    />
                    <SearchIcon fill="white" className="search-icon" />
                </StyledSearchInputWrapper>
            </div>         
        </StyledSearchResultsHeroContainer>
    )
}
