import React, { useEffect, useState } from 'react'

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import SearchResultsHero from '@modules/hero/search-results-hero';
import DotLoader from '@commons/dot-loader';
import BlogCard from '@blog/blog-card';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';
import useQueryParam from '../../hooks/useQueryParam';

// query($searchText: ID!) 

const searchQuery = gql`
 query($searchText: String!){
            posts(where: {search: $searchText}) {
              nodes {
                uri 
                title
                content
                featuredImage {
                    node {
                        mediaItemUrl
                        altText
                    }
                } 
                seo {
                    metaDesc
                }
                author {
                    node {
                        avatar {
                            url
                        }
                        firstName
                        lastName
                    }
                }
              }
            }
        }
`

const isBrowser = typeof window !== 'undefined';

export default function SearchResultsTemplate({ location }) {
    
    const [searchText, setSearchText] = useQueryParam('q', '');

    const [isSearching, setIsSearching] = useState(false);

    const handleChange = (e) => {
        setSearchText(e.target.value);
    }
    const handleKeyDown = (e) => e.key === 'Enter' ? handleSubmit() : null;
    const handleSubmit = () => {}
    
    useEffect(() => {
        if(isBrowser) {
            const urlParams = new URLSearchParams(location.search);
            setSearchText(urlParams.get('q') || '');
        }
        
    }, [location])
    
    return (
        <main className="search-results__template">
            <SearchResultsHero 
                title={`I'm here to search for`}
                searchText={searchText}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                isSearching={isSearching}
            />

            {isBrowser ?
                <Query query={searchQuery} variables={{ searchText }}>
                      {({ loading, error, data }) => {
      
                          if (loading) return (
                              <DotLoader 
                                  isLarge={true} 
                                  isLoading={true}
                                  isAbsolute={false}
                                  color={'black'}
                              />
                          )
                          
                          if (error) return (<SearchResultError 
                            title={'Well... this is awkward.'}
                            subTitle={`
                                You broke our search engine –– congrats! (And also: ouch, stop that!)<br/><br/>
                                <a href="/blog">Click here to go back to the blog and try again.</a>
                            `}
                          />);

                          if (data.posts.nodes.length < 1) return (<SearchResultError 
                            title={'Ohhhh nooooo!'}
                            subTitle={`
                                Our search engine Chihuahuas couldn't sniff out any results.<br/><br/>
                                <a href="/blog">Click here to go back to the blog and try again.</a>
                            `}
                          />);
                          
                          return data.posts.nodes.map((post, i )=> (
                              <BlogCard module={post} key={post.title + i + 'search-results'} />
                          ));
                      }}
                </Query>
            :
                <DotLoader 
                    isLarge={true} 
                    isLoading={true}
                    isAbsolute={false}
                    color={'black'}
                />
            }

  
        </main>
    )
}

const SearchResultError = ({title, subTitle}) => (
    <StyledModuleContainer moduleSpacing="lg" >
        <StyledContainer type="inner">
            <ModuleTitle renderAs="h2">{`${title}`}</ModuleTitle>
            <WysiwygContent>
                {`<p>${subTitle}</p>`}
            </WysiwygContent>
        </StyledContainer>  
    </StyledModuleContainer>
)